<template>
  <div>
    <aRow>
      <aCol :span="24">
        <aRow class="title">
          <aCol :span="24"> <img
            class="relative"
            src="@/assets/images/dashboard/sales/print/travel-bag.png"
            width="16"
            style="top: -2px; margin-right: 3px"
          /> SERVIÇOS </aCol>
        </aRow>
      </aCol>
    </aRow>

    <aRow
      class="product service row"
      v-for="(service, i) in strToJson(contract.meta.service_rows)"
      :key="i"
    >
      <aCol :span="5">
        <div class="label">SERVIÇO</div>
        {{ contract.meta[`service_${service.id}_type`] }}
      </aCol>
      <aCol :span="16">
        <div class="label">DESCRIÇÃO</div>
        {{ contract.meta[`service_${service.id}_name`] }}
      </aCol>
      <aCol :span="3">
        <div class="label">LOCALIZADOR</div>
        #{{ contract.meta[`service_${service.id}_locator`] }}
      </aCol>
    </aRow>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "PrintSaleServicesSection",
  props: {
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass" scoped>
.row
  padding: 5px 0
  border-bottom: 1px solid #eee
  font-size: 13px
.label
  font-size: 10px
  font-weight: 600
  color: #ccc
  margin-bottom: 3px
.title
  margin-top: 10px
  color: #434a54
  border-bottom: 1px solid #ddd
  font-size: 16px
  font-weight: 600
  margin-bottom: 10px
</style>
